(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/bet-events/assets/bet-events.js') >= 0) return;  svs.modules.push('/components/tipsen/bet-events/assets/bet-events.js');
"use strict";


const {
  BetEvent
} = svs.components.tipsen.betEvents;
const {
  clx
} = svs.components.lbUtils;
const {
  DraggableContainer
} = svs.components.lbUi;
const BetEventsContent = _ref => {
  let {
    renderedBetEvents,
    remainingBetEvents
  } = _ref;
  return React.createElement(React.Fragment, null, renderedBetEvents.map(_ref2 => {
    let {
      awayParticipant,
      betEventId,
      homeParticipant
    } = _ref2;
    return React.createElement(BetEvent, {
      awayParticipant: awayParticipant,
      homeParticipant: homeParticipant,
      key: betEventId
    });
  }), remainingBetEvents > 0 && React.createElement("div", {
    className: "pg_bet_events__remaining_count"
  }, "+ ".concat(remainingBetEvents)));
};
const BetEvents = _ref3 => {
  let {
    betEvents = [],
    className,
    limitToNumber
  } = _ref3;
  const renderedBetEvents = limitToNumber && limitToNumber < betEvents.length ? betEvents.slice(0, limitToNumber) : betEvents;
  const remainingBetEvents = betEvents.length - limitToNumber;
  const hasDraggableScrolling = renderedBetEvents.length > 1;
  if (Array.isArray(betEvents) && betEvents.length === 0) {
    return null;
  }
  const classNames = clx('pg_bet_events', className);
  if (hasDraggableScrolling) {
    return React.createElement(DraggableContainer, {
      className: classNames
    }, React.createElement(BetEventsContent, {
      remainingBetEvents: remainingBetEvents,
      renderedBetEvents: renderedBetEvents
    }));
  }
  return React.createElement("div", {
    className: classNames
  }, React.createElement(BetEventsContent, {
    remainingBetEvents: remainingBetEvents,
    renderedBetEvents: renderedBetEvents
  }));
};
setGlobal('svs.components.tipsen.betEvents.BetEvents', BetEvents);

 })(window);