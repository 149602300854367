(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/bet-events/assets/bet-event.js') >= 0) return;  svs.modules.push('/components/tipsen/bet-events/assets/bet-event.js');
"use strict";


const {
  Participant
} = svs.components.tipsen.betEvents;
const BetEvent = _ref => {
  let {
    className,
    awayParticipant = {
      mediumName: 'Bortalag',
      name: 'Bortalag',
      flagISO: 'int'
    },
    homeParticipant = {
      mediumName: 'Hemmalag',
      name: 'Hemmalag',
      flagISO: 'nir'
    },
    showMediumName = false
  } = _ref;
  return React.createElement("div", {
    className: ['pg_bet_event'].concat(className !== null && className !== void 0 ? className : []).join(' ')
  }, React.createElement(Participant, {
    flagISO: homeParticipant.flagISO,
    name: showMediumName ? homeParticipant.mediumName : homeParticipant.name
  }), React.createElement(Participant, {
    flagISO: awayParticipant.flagISO,
    name: showMediumName ? awayParticipant.mediumName : awayParticipant.name
  }));
};
setGlobal('svs.components.tipsen.betEvents.BetEvent', BetEvent);

 })(window);