(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/bet-events/assets/participant.js') >= 0) return;  svs.modules.push('/components/tipsen/bet-events/assets/participant.js');
"use strict";


const {
  flags: {
    getFlag
  },
  ReactFlag
} = svs.lb_ui;
const hasFlagRepresentation = iso => {
  const url = getFlag(iso);
  return url !== 'no-flag-found.svg';
};
const Participant = _ref => {
  let {
    className,
    flagISO,
    name,
    style
  } = _ref;
  return React.createElement("div", {
    className: ['pg_bet_event__participant'].concat(className !== null && className !== void 0 ? className : []).join(' '),
    style: style
  }, flagISO && React.createElement("div", {
    className: "pg_bet_event__participant__flag"
  }, hasFlagRepresentation(flagISO) && React.createElement(ReactFlag, {
    iso: flagISO
  })), React.createElement("div", {
    className: "pg_bet_event__participant__name"
  }, name));
};
setGlobal('svs.components.tipsen.betEvents.Participant', Participant);

 })(window);